<template>
    <div>
        <b-row>
            <!-- left column -->
            <b-col>
                <!-- email, username -->
                <b-form-group
                    :label="$t('user.settings')"
                    class="mb-0"
                    label-class="font-weight-bold pt-0"
                    label-size="md"
                >
                    <!-- email -->
                    <form-email
                        :record.sync="record"
                        :validity-check="validity.check"
                        :validity.sync="validity[json_key]"
                        @eventValidity="setValidity"
                    ></form-email>
                    <!-- username -->
                    <b-form-group
                        :label="$t('user.username')"
                        class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                        description=""
                        label-class="mb-0"
                        label-for="profile.username"
                    >
                        <b-form-input
                            id="profile.username"
                            v-model="record.username"
                            :placeholder="$t('user.username_placeholder')"
                            class="mb-2 mr-sm-2 mb-sm-0"
                        ></b-form-input>
                    </b-form-group>
                    <!-- password -->
                    <form-password
                        :is-new="record.id > 0 ? false : true"
                        :record.sync="record"
                        :validity-check="validity.check"
                        :validity.sync="validity[json_key]"
                        @eventValidity="setValidity"
                    ></form-password>
                    <!-- email_verified_at -->
                    <b-form-group
                        v-show="record.id > 0"
                        :label="$t('user.email_verified_at')"
                        class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                        description=""
                        label-class="mb-0"
                        label-for="profile.emailVerifiedAt"
                    >
                        <b-input-group>
                            <b-form-datepicker
                                id="profile.emailVerifiedAt"
                                v-model="record.email_verified_at"
                                :label-close-button="$t('form.datepicker.close')"
                                :label-reset-button="$t('form.datepicker.reset')"
                                :label-today-button="$t('form.datepicker.today')"
                                :locale="__getVueLocale()"
                                close-button
                                reset-button
                                today-button
                            >
                            </b-form-datepicker>
                            <!--                            <b-input-group-append>-->
                            <!--                                <b-button variant="outline-success">x</b-button>-->
                            <!--                            </b-input-group-append>-->
                        </b-input-group>
                    </b-form-group>
                </b-form-group>
            </b-col>
            <!-- right column -->
            <b-col>
                <!-- avatar -->
                <form-avatar
                    :record="record"
                    :picture="picture"
                ></form-avatar>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import FormAvatar from "@/components/general/elements/pictures/avatar/FormAvatar";
import FormPassword from "@/components/general/elements/formElements/FormPassword";
import FormEmail from "@/components/general/elements/formElements/FormEmail";

export default {
    name: "FormAdminProfile",
    components: {FormEmail, FormPassword, FormAvatar},
    props: {
        json_key: {
            type: String,
            default() {
                return 'profile'
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        picture: {
            type: Object,
            default() {
                return {}
            }
        },
        validity: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        // <-- validity -->
        setValidity() {
            let validity = null;
            Object.keys(this.validity[this.json_key]).forEach(key => {
                if (key !== 'check' && this.validity[this.json_key][key] === false) {
                    validity = false;
                }
            });
            // <--  -->
            this.$set(this.validity[this.json_key], 'check', validity === null);
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
