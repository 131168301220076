<template>
    <b-card-text>
        <!-- avatar -->
        <b-form-group
            :label="$t('user.avatar')"
            class="mb-0"
            label-class="font-weight-bold pt-0"
            label-size="md"
        >
            <b-avatar
                :src="src"
                :variant="src === null ? 'secondary' : 'null'"
                button
                class="mt-1 ml-2"
                size="6em"
                style="overflow: hidden;"
                @click="$refs.fileInput.$el.querySelector('input[type=file]').click()"
            ></b-avatar>
            <!-- style="overflow: hidden; object-fit:contain; REMARK is for brave browser 0.62.51, firefox, chrome do not need!!!"-->
            <!-- REMARK b-form-file options: DO NOT DELETE!!!
                    :browse-text="$tc('picture.add_picture', multiple ? 2 : 1)"
                    :drop-placeholder="$t('picture.drop_files_here')"
                    :file-name-formatter="formatNames"
                    :multiple="multiple"
                    :placeholder="$t('picture.no_picture_chosen')"
                    accept="image/jpeg, image/png, image/gif"
                    no-drop-placeholder="Not allowed"
                    @change="addFiles"
             -->
            <b-form-file
                ref="fileInput"
                v-model="file"
                style="display:none;"
            ></b-form-file>
        </b-form-group>
        <!-- add/remove avatar -->
        <b-form-group class="mt-2">
            <!-- add avatar -->
            <b-button
                v-if="src === null"
                size="sm"
                variant="secondary"
                @click="$refs.fileInput.$el.querySelector('input[type=file]').click()"
            >
                {{$t('picture.add_avatar')}}
            </b-button>
            <!-- remove avatar -->
            <b-button
                v-else
                size="sm"
                variant="danger"
                @click="removeAvatar()"
            >
                {{$t('picture.remove_avatar')}}
            </b-button>
        </b-form-group>
    </b-card-text>
</template>

<script>
export default {
    name: "FormAvatar",
    components: {},
    props: {
        json_key: {
            type: String,
            default() {
                return 'avatar'
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        picture: {
            type: Object,
            default() {
                return {}
            }
        },
        division: {
            type: String,
            default() {
                return 'avatar'
            }
        },
    },
    data() {
        return {
            file: null,
            remove: false,
            src: null,
        }
    },
    mounted() {
    },
    methods: {
        setAvatar() {
            let picture = this.picture;
            if (picture === null || picture.image === undefined) {
                return null;
            }
            this.src = this.__getImageSrc(picture.image.src);
        },
        addAvatar() {
            if (this.file !== null) {
                this.remove = false;
                // <-- update record -->
                let data = {
                    file: this.file,
                    src: null,
                    division: this.division
                }
                this.$set(this.record, this.json_key, data);
                // <--  -->
                this.src = URL.createObjectURL(this.file);
            }
        },
        removeAvatar() {
            this.remove = true;
            let data = {
                remove: true,
            }
            this.$set(this.record, this.json_key, data);
            this.src = null;
            this.remove = true;
        }
    },
    computed: {},
    filters: {},
    watch: {
        'picture': {
            handler: function (value, oldValue) {
                this.setAvatar();
            },
            deep: true
        },
        'file': {
            handler: function (value, oldValue) {
                this.addAvatar();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
