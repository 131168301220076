<template>
    <b-form-group
        :label="$t('auth.email')"
        class="mb-2 mr-sm-2 mb-sm-0"
        label-class="mb-0"
        label-for="form-email"
    >
        <b-form-input
            id="form-email"
            v-model="record[json_key]"
            :placeholder="$t('auth.email_placeholder')"
            type="email"
            :state="validityEmail"
            required
        ></b-form-input>
        <b-form-invalid-feedback :state="validityEmail">
            <div v-html="$t('auth.email_warning')"/>
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
export default {
    name: "FormEmail",
    components: {},
    props: {
        json_key: {
            type: String,
            default() {
                return 'email'
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        validityCheck: {
            type: Boolean,
            default() {
                return false;
            }
        },
        validity: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        validityEmail() {
            if (!this.validityCheck) {
                return null;
            }
            // <-- value -->
            let value = this.record[this.json_key];
            // value = value.toLowerCase();
            let re = /^(([^<>()[\]\\.,;ščćžđ:\s@"]+(\.[^<>()[\]\\.,;ščćžđ:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let validity = re.test(value);
            validity = validity ? null : validity;
            // <--  -->
            this.$set(this.validity, this.json_key, validity);
            // <--  -->
            this.$emit('eventValidity')
            return validity;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
