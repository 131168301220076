<template>
    <b-form-group
        :label="$t('user.password')"
        class="mb-2 mr-sm-2 mb-sm-0 pt-2"
        label-class="mb-0"
        label-for="form-password"
    >
        <b-input-group>
            <b-form-input
                id="form-password"
                v-model="record[json_key]"
                :placeholder="this.isNew ? $tc('form.password.warning', 1) : null"
                :type="showPassword === true ? 'text' : 'password'"
                :state="validityPassword"
                :required="this.isNew"
            ></b-form-input>
            <!-- append: hide -->
            <b-input-group-append>
                <b-button
                    v-b-tooltip.hover="{variant: 'info'}"
                    :title="$t('form.password.show')"
                    @click="showPassword = !showPassword"
                >
                    <b-icon
                        :icon="showPassword ? 'eye' : 'eye-slash'"
                        scale="0.9"
                    ></b-icon>
                </b-button>
            </b-input-group-append>
            <!-- append: info -->
            <b-input-group-append>
                <b-button
                    variant="info"
                    v-b-toggle.collapse-info
                >?</b-button>
            </b-input-group-append>
        </b-input-group>
        <!-- validity feedback -->
        <b-form-invalid-feedback :state="validityPassword">
            <div v-html="$tc('form.password.warning', 1)"/>
        </b-form-invalid-feedback>
        <!-- collapse:message -->
        <b-collapse id="collapse-info">
            <b-card class="bg-info">
                <div v-html="$t('form.password.message_info')"></div>
            </b-card>
        </b-collapse>
    </b-form-group>
</template>

<script>
export default {
    name: "FormPassword",
    components: {},
    props: {
        json_key: {
            type: String,
            default() {
                return 'password'
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        validityCheck: {
            type: Boolean,
            default() {
                return false;
            }
        },
        validity: {
            type: Object,
            default() {
                return {};
            }
        },
        isNew: {
            type: Boolean,
            default() {
                return true
            }
        },
    },
    data() {
        return {
            showPassword: false,
        }
    },
    mounted() {
    },
    methods: {},
    computed: {
        validityPassword() {
            if (!this.validityCheck) {
                return null;
            }
            // <-- value -->
            let value = this.record[this.json_key];
            let validity = value === null || value.length < 6 ? false : null;

            if(!this.isNew && (value === null || value.length === 0)){
                validity = null;
            }
            // <--  -->
            this.$set(this.validity, this.json_key, validity);
            this.$emit('eventValidity');
            return validity;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
