<template>
    <div class="table-detail text-left bg-secondary-light p-2">
        <!-- profile -->
        <b-card :sub-title="$t('user.profile')" body-class="pb-0">
            <b-row>
                <b-col v-for="data in getProfile" cols="6" lg="3" md="4">
                    <b-form-group
                        :label="data.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        {{data.value}}
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <!-- personal data -->
        <b-card :sub-title="$t('user.personal_data')" body-class="pb-0">
            <b-row>
                <!-- name -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getName.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div>{{getName.value}}</div>
                    </b-form-group>
                </b-col>
                <!-- address -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getAddress.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div v-if="getAddress.state_id < 0" v-html="getAddress.value"></div>
                        <div v-else-if="getAddress.state_id > 0">
                            <div>{{getAddress.value.street}}</div>
                            <div>{{getAddress.value.post_office}}</div>
                            <div>{{getAddress.value.state}}</div>
                            <div class="mt-1">{{getAddress.value.region}}</div>
                        </div>
                    </b-form-group>
                </b-col>
                <!-- contact -->
                <b-col v-for="data in getContact" cols="6" lg="3" md="4">
                    <b-form-group
                        :label="data.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div v-for="value in data.value" v-if="value.value_b !== null && value.value_c !== null">
                            <div v-if="value.deprecated">
                                <span class="text-secondary"><del>{{value.value_b}}</del></span>:
                                <del>{{value.value_c}}</del>
                            </div>
                            <div v-else>
                                <span class="text-secondary">{{value.value_b}}</span>:
                                {{value.value_c}}
                            </div>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
export default {
    name: "ShowAdminUser",
    components: {},
    props: {
        content: {
            type: Object,
            default() {
                return {}
            }
        },
        // data: {
        //     type: Object,
        //     default() {
        //         return {}
        //     }
        // },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        getProfile() {
            return [
                {
                    label: this.$t('user.email'),
                    value: this.content.email
                },
                {
                    label: this.$t('user.username'),
                    value: this.content.username
                },
                {
                    label: this.$t('user.email_verified_at'),
                    value: this.__convertBaseDateToLocaleDateString(this.content.email_verified_at)
                },
            ];
        },
        getName() {
            if (this.content.personal_data.length === 0 || this.content.personal_data[0] === undefined) {
                return {};
            }
            // <--  -->
            let name = this.content.personal_data[0].name;

            let value = name.name == null ? '' : name.name;
            value += value.length > 0 ? ' ' : '';
            value += name.surname == null ? '' : name.surname;
            // <--  -->
            return {
                label: this.$t('user.name') + ', ' + this.$t('user.surname').toLowerCase(),
                value: value,
            };
        },
        getAddress() {
            if (this.content.personal_data.length === 0 || this.content.personal_data[0] === undefined) {
                return {};
            }
            // <--  -->
            let record = this.content;
            let address = record.personal_data[0].address;
            let post_office = record.post_office[0];
            let region = record.region[0];
            // <--  -->
            let value = address.state_id < 0
                ? address.full.replace('\n', '<br>')
                : {
                    street: address.street,
                    post_office: post_office === undefined ? null : post_office.post_code + ' ' + post_office.city,
                    state: record.state === undefined ? null : record.state.name,
                    region: region === undefined || region.meta === undefined ? null : region.meta.name
                }
            // <--  -->
            return {
                state_id: address.state_id,
                label: this.$t('address.address'),
                value: value,
            };
        },
        getContact() {
            let array = [];
            if (this.content.personal_data.length === 0 || this.content.personal_data[0] === undefined) {
                return array;
            }
            // <--  -->
            let record = this.content.personal_data[0].contact;
            Object.keys(record).find((label) => {
                array.push(
                    {
                        label: this.$t(`user.${label}`),
                        value: record[label]
                    },
                );
            });
            // <--  -->
            return array;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
