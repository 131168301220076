<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        size="xl"
        @ok="formSave"
        @show="modalOpen"
    >
        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm"
                   variant="white">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab profile -->
                        <b-tab :active="!record.id > 0">
                            <template #title>
                                <b-icon v-if="tabBasicValidity"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('admin.user.tab.profile')}}
                            </template>
                            <!-- form admin profile -->
                            <form-admin-profile
                                :picture="record.personal_data === undefined ? null : record.personal_data.picture"
                                :record.sync="record.profile"
                                :validity.sync="validity"
                            ></form-admin-profile>
                        </b-tab>
                        <!-- tab personal data -->
                        <b-tab :active="record.id > 0" :title="$t('admin.user.tab.personal_data')">
                            <!-- form personal data -->
                            <form-personal-data
                                :data="data"
                                :record="record.personal_data"
                                :record-clone="recordClone.personal_data"
                                :record-default="recordDefault.personal_data"
                            ></form-personal-data>
                        </b-tab>
                        <!-- roles -->
                        <b-tab :title="$tc('admin.user.tab.role', 0)">
                            <b-form-group
                                :label="$tc('admin.user.tab.role', 0)"
                                class="mb-0"
                                label-class="font-weight-bold pt-0"
                                label-size="md"
                            >
                                <b-table
                                    id="role-table"
                                    :fields="table.columns"
                                    :items="getTableRoleItems"
                                    bordered head-row-variant="dark" hover outlined
                                    responsive
                                    striped
                                    table-variant="light"
                                    thead-class="text-center"
                                >
                                    <!-- id -->
                                    <template v-slot:cell(id)="row">
                                        <div class="d-flex justify-content-center">
                                            {{row.item.id}}
                                        </div>
                                    </template>
                                    <!-- member -->
                                    <template v-slot:cell(member)="row">
                                        <div class="d-flex justify-content-around">
                                            <b-form-checkbox
                                                v-model="row.item.member"
                                                @change="changeRole(row.item)"
                                            ></b-form-checkbox>
                                        </div>
                                    </template>
                                    <!-- notes -->
                                    <template v-slot:cell(notes)="row">
                                        <div class="d-flex justify-content-start" v-html="row.item.notes"></div>
                                    </template>
                                </b-table>
                            </b-form-group>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
        <!-- alert error-->
        <b-alert
            v-if="errors !== null"
            :show="errors.message != null"
            class="mb-0"
            dismissible
            variant="danger"
            @dismissed="resetErrors"
        >
            <div v-html="__formatErrorMessage(errors.message)"></div>
        </b-alert>
    </b-modal>
</template>

<script>
import FormPersonalData from "@/components/manager/personalData/FormPersonalData";
import Organisation from "@/components/manager/organisation/Organisation";
import FormAdminProfile from "@/components/admin/user/FormAdminProfile";
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";

export default {
    name: "ModalFormUser",
    components: {FormIdActive, FormAdminProfile, Organisation, FormPersonalData},
    props: {
        localeKey: null,
        uri: null,
        form: {},
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            data: {},
            // <-- record -->
            recordDefault: {
                id: 0,
                // active: true,
                // order: 0,
                // meta: {
                //     name: '',
                // },
                // locale: {},
                profile: {
                    email: null,
                    username: null,
                    password: null,
                },
                personal_data: {
                    name: {
                        name: null,
                        surname: null
                    },
                    contact: {},
                    address: {
                        state_id: null,
                        postOffice_id: null,
                        region_id: null,
                        street: null,
                        full: null
                    },
                    role_stamps: []
                },
            },
            record: {},
            recordClone: {},
            // <-- roles -->
            table: {
                columns: [
                    {
                        key: 'id',
                        label: this.$t('form.id'),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'stamp',
                        label: this.$t('form.stamp'),
                        sortable: true,
                        show: true,
                        // thStyle: {width: '30%'}
                    },
                    {
                        key: 'role',
                        label: this.$t('form.role'),
                        sortable: true,
                        show: true,
                        thStyle: {width: '30%'}
                    },
                    {
                        key: 'member',
                        label: this.$t('form.member'),
                        sortable: true,
                        show: true,
                        thStyle: {width: '20%'}
                    },
                    {
                        key: 'notes',
                        label: this.$t('form.description'),
                        show: true,
                    },
                ],
            },
            // <-- validity -->
            validityDefault: {
                check: false,
                profile: {},
            },
            validity: {},
            // <-- errors -->
            errorsDefault: {
                message: null,
            },
            errors: null,
        }
    },
    mounted() {
    },
    destroyed() {
        this.$set(this, 'record', null);
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'validity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // <-- role -->
        changeRole(row) {
            // <-- add membership -->
            if (row.member) {
                this.record.personal_data.role_stamps.push(row.stamp);
                return;
            }
            // <-- remove membership -->
            let index = this.record.personal_data.role_stamps.indexOf(row.stamp);
            this.record.personal_data.role_stamps.splice(index, 1);
        },
        // <-- table -->
        tableRowDetail(locale) {
            // let detail = null;
            // Object.keys(locale).find((n) => {
            //     if (n === this.$i18n.locale) {
            //         detail = locale[n].notes;
            //     }
            // });
            // return detail;
        },
        // <-- modal -->
        modalOpen() {
            this.resetValidity();
            this.resetErrors();
            // <--  -->
            if (this.form.id > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- form -->
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            this.validity.check = true;
            let validity = true;
            // <-- check -->
            this.$nextTick(() => {
                Object.keys(this.validity).forEach(key => {
                    if (key !== 'check' && this.validity[key].check === false) {
                        validity = false;
                    }
                });
                validity = validity && this.$refs.form.checkValidity();
                // <-- save -->
                if (validity) {
                    if (this.record.id > 0) {
                        this.baseUpdateFormData();
                        return;
                    }
                    this.baseInsertFormData();
                }
            });
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            this.$nextTick(() => {
                this.$set(this, 'record', this.__clone(this.recordDefault));
                this.$set(this, 'recordClone', this.__clone(this.record));
            });
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignData`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.form.id}`, config)
                .then((response) => {
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    let record = response.data.record;
                    record.id = response.data.record.profile.id;
                    this.__mergeObjectDeep(this.recordDefault, record);
                    this.$set(this, 'record', record);
                    this.$set(this, 'recordClone', this.__clone(this.record));
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                });
        },
        // <-- base -->
        baseInsertFormData() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            }
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.profile.avatar !== undefined) {
                formData.append('file', this.record.profile.avatar.file);
            }
            // <-- prepare data for axios -->
            formData.append('locale', JSON.stringify(this.$i18n.locale));
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            this.axios.post(this.uri.uri1 + '/form_data_insert' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdateFormData() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            }
            let formData = new FormData();
            let imagesUpdate = [];
            // <-- prepare files for axios -->
            if (this.record.profile.avatar !== undefined) {
                formData.append('file', this.record.profile.avatar.file);
            }
            // <-- prepare data for axios -->
            formData.append('locale', JSON.stringify(this.$i18n.locale));
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            this.axios.post(this.uri.uri1 + '/form_data_update' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.form.id <= 0 || record === null || record.meta === undefined) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.meta.name;
                Object.keys(record.locale).find((n) => {
                    if (n === this.$i18n.locale) {
                        if (!this.__isNull(record.locale[n].name)) {
                            name = record.locale[n].name;
                        }
                    }
                });
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            }
        },
        // <-- roles -->
        getTableRoleItems() {
            if (this.data.roles === undefined) {
                return [];
            }
            // <--  -->
            let items = [];
            let item = {};
            // <--  -->
            let roleName, notes;
            let localeTemp;
            this.data.roles.forEach(role => {
                // <-- roleName -->
                roleName = role.meta.name;
                localeTemp = this.__getJsonLocaleValue(role.locale, 'name');
                roleName = localeTemp !== null ? localeTemp : roleName;
                // <-- description -->
                notes = null;
                localeTemp = this.__getJsonLocaleValue(role.locale, 'notes');
                notes = localeTemp !== null ? localeTemp : notes;
                item = {
                    id: role.id,
                    stamp: role.stamp,
                    role: roleName,
                    member: this.record.personal_data.role_stamps.includes(role.stamp),
                    notes: notes
                }
                items.push(item);
            });

            // <--  -->
            return items;
        },
        // <-- locale -->
        setRecordLocale() {
            // this.data.locale.forEach((value, index) => {
            //     // <-- add missing locale record -->
            //     if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
            //         this.$set(this.record.locale, value.locale, {
            //             name: '',
            //             notes: ''
            //         });
            //     }
            // });
            // return this.record.locale;
        },
        // <-- tabs validity -->
        tabBasicValidity() {
            return this.validity.profile.check === false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
