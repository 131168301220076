<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-container class="mt-3" fluid>
                <!-- table toolbar -->
                <table-toolbar
                    :columns-filter="tableToolbarColumnsShow"
                    :columns-show="tableToolbarColumnsShow"
                    :locale-key="localeKey"
                    :options="tableToolbar.options"
                    :response.sync="tableToolbar.response"
                    @eventNew="toolbarTableEventNew"
                    @eventRefresh="toolbarTableEventRefresh"
                ></table-toolbar>
                <!-- table -->
                <b-table
                    id="main-table"
                    :current-page="tableToolbar.options.pagination.currentPage"
                    :fields="tableColumns"
                    :filter="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.search"
                    :filterIncludedFields="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.columnsOn"
                    :items="tableItems"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                    bordered head-row-variant="dark" hover outlined
                    responsive
                    striped
                    table-variant="light"
                    thead-class="text-center"
                    @filtered="onFiltered"
                >
                    <!-- id -->
                    <template v-slot:cell(id)="row">
                        <div class="d-flex justify-content-center">
                            {{row.item.id}}
                        </div>
                    </template>
                    <!-- active -->
                    <template v-slot:cell(active)="row">
                        <div class="d-flex justify-content-around">
                            <b-icon-sun
                                :variant="row.item.active ? 'success' : 'danger'"
                                scale="1.2"
                            ></b-icon-sun>
                        </div>
                    </template>
                    <!-- actions -->
                    <template v-slot:cell(action)="row">
                        <div class="d-flex justify-content-around">
                            <!-- detail show -->
                            <b-icon-caret-down-square
                                v-if="!row.detailsShowing"
                                v-b-tooltip.hover
                                :title="$t('form.detail_show')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="row.toggleDetails"
                            ></b-icon-caret-down-square>
                            <!-- detail hide -->
                            <b-icon-caret-up-square-fill
                                v-if="row.detailsShowing"
                                v-b-tooltip.hover
                                :title="$t('form.detail_hide')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="row.toggleDetails"
                            ></b-icon-caret-up-square-fill>
                            <!-- edit -->
                            <b-icon-file-text
                                v-if="!row.item.system"
                                v-b-tooltip.hover
                                :title="$t('form.edit')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="recordEdit(row.item)"
                            ></b-icon-file-text>
                            <!-- delete -->
                            <b-icon-trash
                                v-if="!row.item.system"
                                v-b-tooltip.hover
                                :title="$t('form.delete')"
                                scale="1.2"
                                style="cursor: pointer;"
                                variant="danger"
                                @click="recordDelete(row.item)"
                            ></b-icon-trash>
                        </div>
                    </template>
                    <!-- row detail -->
                    <template v-slot:row-details="row">
                        <show-admin-user :content="tableRowDetail(row.item.id)"></show-admin-user>
                    </template>
                </b-table>
                <!-- pagination -->
                <b-pagination
                    v-model="tableToolbar.options.pagination.currentPage"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :total-rows="this.tableToolbar.options.filterCount"
                    aria-controls="main-table"
                    limit="20"
                    size="sm"
                ></b-pagination>
            </b-container>
        </b-overlay>
        <!-- modalFormXxx -->
        <ModalFormUser
            :form="form"
            :locale-key="localeKey"
            :uri="uri"
            @refresh="modalEventRefresh"
        ></ModalFormUser>
    </div>
</template>

<script>
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";
import TableDetailHtml from "@/components/general/elements/tableAccessories/TableDetailHtml";
import ModalFormUser from "@/components/admin/user/ModalFormUser";
import ShowAdminUser from "@/components/admin/user/ShowAdminUser";

export default {
    name: "Users",
    components: {ShowAdminUser, ModalFormUser, TableDetailHtml, TableToolbar},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            localeKey: 'admin.user.main',
            uri: {
                main: `api/v1/admin/users`,
                uri1: `api/v1/admin/user`,
            },
            table: {
                items: [],
                fields: [],
                columns: [
                    // <-- user -->
                    {
                        key: 'id',
                        label: this.$t('form.id'),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'email',
                        label: this.$t('admin.user.email'),
                        sortable: true,
                        alwaysShow: true,
                    },
                    {
                        key: 'username',
                        label: this.$t('admin.user.username'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'email_verified_at',
                        label: this.$t('user.email_verified_at'),
                        formatter: (value, key, item) => {
                            return this.__convertBaseDateToLocaleDateString(value)
                        },
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'name',
                        label: this.$t('user.name'),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'surname',
                        label: this.$t('user.surname'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'phone',
                        label: this.$t('user.phone'),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'internet_contact',
                        label: this.$t('user.internet_contact'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'region',
                        label: this.$t('address.region'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'state',
                        label: this.$t('address.state'),
                        sortable: true,
                        show: false,
                    },
                    // <--  -->
                    // {
                    //     key: 'order',
                    //     label: this.$t('form.order'),
                    //     sortable: true,
                    //     show: false,
                    // },
                    // {
                    //     key: 'active',
                    //     label: this.$t('form.active'),
                    //     sortable: true,
                    //
                    // },
                ],
                sortBy: 'id',
                sortDesc: false,
            },
            tableToolbar: {
                options: {
                    filterCount: 0,
                    rowsCount: 0,
                    pagination: {
                        perPage: 10,
                        currentPage: 1,
                    },
                },
                response: {}
            },
            form: {},
            // <-- set -->
            data: [],
        }
    },
    mounted() {
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- toolbarTable -->
        toolbarTableEventRefresh() {
            this.baseSelect();
        },
        toolbarTableEventNew() {
            this.recordNew();
        },
        // <-- modalForm -->
        modalEventRefresh(data) {
            this.$set(this, 'data', data);
        },
        // <-- table -->
        onFiltered(filteredItems) {
            this.tableToolbar.options.filterCount = filteredItems.length;
            this.tableToolbar.options.pagination.currentPage = 1;
        },
        tableRowDetail(id) {
            let content = this.data.records.find((n) => {
                if (n.id === id) {
                    if (n.post_office[0] !== undefined && n.id === id) {
                        n.state = this.__getStateObject(this.data.data.states, n.post_office[0].state_id);
                    }
                    return n;
                }
            });
            return content;
        },
        // <-- record -->
        recordNew() {
            this.$set(this.form, 'id', 0);
            this.$bvModal.show('ModalFormUser');
        },
        recordEdit(row) {
            this.$set(this.form, 'id', row.id);
            this.$bvModal.show('ModalFormUser');
        },
        recordDelete(row) {
            const h = this.$createElement;
            const messageHTML = h('div', [
                h('span', [
                    `${this.$t('form.delete_message.pre')} ${this.$tc(this.localeKey, 2)} `,
                    h('strong', row.email), this.$t('form.delete_message.post'),
                ])
            ]);
            // <-- message box -->
            this.$bvModal.msgBoxConfirm([messageHTML], {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.delete'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.baseDelete(row.id);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        // <-- api calls -->
        baseSelect: function () {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    this.showOverlay(false);
                });
        },
        baseDelete(record_id) {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.delete(this.uri.main + `/${record_id}`, config)
                .then((response) => {
                    this.data = response.data;
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        // <-- tableToolbar -->
        tableToolbarColumnsShow() {
            let columns = this.table.columns;
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            columns.forEach(column => {
                if (!column.alwaysShow) {
                    values.push({
                        key: column.key,
                        label: column.label,
                        sortable: column.sortable,
                        show: column.show,
                    });
                }
            });
            // <--  -->
            return values;
        },
        // <-- table -->
        tableColumns() {
            let columns = this.table.columns;
            let columnsShow = this.tableToolbar.response.columnsShow;
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            columns.forEach(column => {
                if (column.alwaysShow || (columnsShow != null && columnsShow.indexOf(column.key) >= 0)) {
                    values.push({
                        key: column.key,
                        label: column.label,
                        formatter: column.formatter,
                        sortable: column.sortable,
                        show: column.show,
                    });
                }
            });
            // <-- add column actions -->
            values.push({
                key: 'action',
                label: '',
                id: 0,
                sortable: false,
                thStyle: {width: '90px'}    //85
                // variant: 'danger',
            });
            // <--  -->
            return values;
        },
        tableItems() {
            if (this.data.records === undefined) {
                return [];
            }
            // <--  -->
            let records = this.data.records;
            // <-- check if exists -->
            if (records.length === 0) {
                return [];
            }
            // <--  -->
            let items = [];
            let item = {};
            let email, username, email_verified_at, notes;
            let name, contact, region;
            records.forEach(record => {
                email = record.email;
                username = record.username;
                email_verified_at = record.email_verified_at;
                name = record.personal_data[0] === undefined ? null : record.personal_data[0].name;
                contact = record.personal_data[0] === undefined ? null : this.__getTableValuesObjectDefault(record.personal_data[0].contact);
                region = record.personal_data[0] === undefined ? null : record.region[0];
                // <-- set item -->
                item = {
                    id: record.id,
                    email: email,
                    username: username,
                    email_verified_at: email_verified_at,
                    name: name === null ? '' : name.name,
                    surname: name === null ? '' : name.surname,
                    phone: contact === null || contact.phone.value_c === null ? null : contact.phone.value_c,
                    internet_contact: contact === null || (contact.internet_contact.value_b === null && contact.internet_contact.value_c === null) ? null : `${contact.internet_contact.value_b}: ${contact.internet_contact.value_c}`,
                    region: region === null || region === undefined ? null : region.meta.name,
                    state: region === null || region === undefined ? null : this.__getStateObject(this.data.data.states, region.state_id).name,

                    order: record.order,
                    active: record.active,
                }
                items.push(item);
            });
            // <--  -->
            this.tableToolbar.options.filterCount = items.length;
            this.tableToolbar.options.rowsCount = items.length;
            return items;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
